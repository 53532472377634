<template>
	<!-- HEADER -->
	<nav class="navbar navbar-expand-lg navbar-dark" data-navbar="static" style="background: #fff !important;">
		<div class="container">

			<div class="navbar-left">
				<button class="navbar-toggler" type="button">☰</button>
				<a class="navbar-brand" href="#">
					<img class="logo-dark" src="@/assets/img/logo-dark.png" alt="logo" style="padding-top: 10px; padding-bottom: 10px;">
					<img class="logo-light" src="@/assets/img/logo-light.png" alt="logo" style="padding-top: 10px; padding-bottom: 10px;">
				</a>
			</div>

			<section class="navbar-mobile">
			<nav class="nav nav-navbar ml-auto">
				<a class="nav-link active" href="#"><span style="color: #39B54A">HOME</span></a>
				<a class="nav-link" href="#inspiration">INSPIRATION</a>
				<a class="nav-link" href="#design">DESIGN PROCESS</a>
				<a class="nav-link" href="#future">FUTURE PLANS</a>
				<a class="nav-link" href="#awards">AWARDS</a>
				<a class="nav-link" href="#team">TEAM</a>
			</nav>
			</section>

		</div>
	</nav>

	<!-- COVER -->
	<header class="header text-white h-100" style="padding-bottom: 146px; background: transparent !important; overflow: hidden;">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-6">
					<h1 class="display-4 fw-600" style="line-height: 52px; font-family: Helvetica, sans-serif;">
						Underwater<br>
						Communication<br>
						<span style="color: #39B54A">Systems</span>
					</h1> 
					<p style="font-family: 'Helvetica Neue', sans-serif; font-weight: 300; line-height: 23px; font-size: 20px; margin-top: 30px;">BuddyTalkie is a marine-friendly biomimetics device, inspired by deep-sea creatures. It integrates a diving flashlight with full duplex optical voice communication, revolutionizing underwater exploration with innovation by combining technology and nature's wisdom.</p>
				</div>
				<div class="col-lg-6 ml-auto" style="position: relative;">
					<div style="
						width: 643px;
						height: 592px;
						flex-shrink: 0;
						border-radius: 643px;
						background: rgba(57, 181, 74, 0.35);
						filter: blur(40px);
						position: absolute;
						top: -100px;
						right: -300px;
						z-index: 1;">
					</div>
					<img class="mt-5" src="@/assets/img/device-2.png" alt="img" style="z-index: 3; position: relative;">
				</div>
			</div>
		</div>
	</header>
	
	<!-- INSPIRATION -->
	<section class="section" id="inspiration" style="background: #151515; padding-top: 60px; padding-bottom: 90px;">

		<div class="container">
			<header class="section-header">
				<h2 style="font-family: Helvetica, sans-serif; font-size: 48px; color: #39B54A; font-weight: 700;">INSPIRATION</h2>
				<hr>

				<h4 style="margin-bottom: 16px; color: #FFF; font-family: 'Helvetica Neue', sans-serif; font-size: 24px; font-weight: 700;">Exploration</h4>
				<p style="color: #FFF; font-family: 'Helvetica Neue', sans-serif; font-weight: 300; font-size: 20px; line-height: 27px;">%71 of the Earth is covered with water and humans have never stopped exploring the oceans with the ambition of diving deeper. Most of the comprehensive ocean explorations made to the underwater world, and it became a great interest to the societies.</p>

				<hr>

				<h4 style="margin-bottom: 16px; color: #FFF; font-family: 'Helvetica Neue', sans-serif; font-size: 24px; font-weight: 700;">Challenges</h4>
				<p style="color: #FFF; font-family: 'Helvetica Neue', sans-serif; font-weight: 300; font-size: 20px; line-height: 27px;">Underwater wireless communication (UWC) makes these underwater explorations possible. However, bidirectional UWC is a challenging task because the current wireless communication methods do not work under the water due to the physical properties of the water medium. Current technology uses ultrasonic acoustic waves to perform UWC, but has drawbacks on marine life by causing underwater noise pollution.</p>
			</header>
		</div>
	</section>

	<!-- HOW IT IS DIFFERENT -->
	<section class="section" id="how" style="background-color: #39B54A; padding-top: 32px; padding-bottom: 32px;">
		<div class="container">

			<div class="row gap-y align-items-center">
				<div class="col-md-6 ml-auto">
					<h4 style="font-family: 'Helvetica Neue', sans-serif; color: #FFF; font-size: 48px;">How it is different?</h4>
					<p style="font-family: 'Helvetica Neue', sans-serif; font-weight: 300; color: #000; line-height: 23px; font-size: 20px;">BuddyTalkie's design sets it apart from other similar products in several key ways. Firstly, it takes a marine-friendly approach by drawing inspiration from the bioluminescent properties of deep-sea fishes, using light-based communication to minimize disruption to marine life and reduce underwater noise pollution.</p>
				</div>

				<div class="col-md-5 order-md-first">
					<img src="@/assets/img/device.png" data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">
				</div>
			</div>

		</div>
	</section>
		
	<!-- DESIGN PROCESS -->
	<section class="section" id="design">
		<div class="container">

			<div class="row gap-y align-items-center">
				<div class="col-md-6 mr-auto">
					<h4 style="
						color: #39B54A;
						font-family: Helvetica;
						font-size: 48px;
						font-style: normal;
						font-weight: 700;
						margin-bottom: 24px;
					">Design Process</h4>
					<p style="
						color: #FFF;
						font-family: Helvetica Neue;
						font-size: 20px;
						font-style: normal;
						font-weight: 300;
						line-height: 24px;
					">The design process began with thorough research into the challenges of underwater communication and the impact of existing technologies on marine life. Drawing inspiration from the bioluminescent properties of deep-sea creatures, the team conceptualized a marine-friendly communication device for underwater exploration. Prototyping played a crucial role in the design process.</p>
					<p style="
						color: #FFF;
						font-family: Helvetica Neue;
						font-size: 20px;
						font-style: normal;
						font-weight: 300;
						line-height: 24px;
						margin-top: 36px;
					">Multiple iterations were created to test different design aspects and functionalities. These prototypes were subjected to rigorous testing in simulated underwater conditions, allowing for evaluation of performance, usability, and safety. Valuable feedback from divers and experts guided iterative improvements, ensuring the device's reliability, durability, and user comfort. Throughout the design process, collaboration with experts in underwater exploration and communication played a vital role.</p>
				</div>

				<div class="col-md-6">
					<img src="@/assets/img/adam.png" data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">
				</div>
			</div>

			<div class="row gap-y align-items-center" style="margin-top: 36px;">
				<div class="col-md-6 ml-auto">
					<h4 style="
						color: #39B54A;
						font-family: Helvetica;
						font-size: 48px;
						font-style: normal;
						font-weight: 700;
						margin-bottom: 24px;
					">How it works?</h4>
					<p style="
						color: #FFF;
						font-family: Helvetica Neue;
						font-size: 20px;
						font-style: normal;
						font-weight: 300;
						line-height: 24px;
					">BuddyTalkie is an underwater optical voice communication device with full duplex communication capabilities. Instead of using Acoustic or Radio waves, it uses light to perform communication. Sound waves travel in water medium, much faster than in the air and critically affected by the physical properties of the water. However, there are some marine creatures that also use ultrasonic waves for communication and hunting. As these creatures affected by these communication method, it drives us to find a more marine-friendly way to perform UWC.</p>
				</div>

				<div class="col-md-5 order-md-first">
					<img src="@/assets/img/adamlar.png" data-aos="fade-up" data-aos-duration="2000" class="aos-init aos-animate">
				</div>
			</div>

		</div>
	</section>

	<!-- FUTURE PLANS -->
	<section class="section" id="future" style="background: #FFF; padding-top: 60px; padding-bottom: 20px;">
		<div class="container">
			<header class="section-header">
				<h2 style="font-family: Helvetica, sans-serif; font-size: 48px; color: #39B54A; font-weight: 700;">Future Plan</h2>

				<p style="color: #000; font-family: 'Helvetica Neue', sans-serif; font-weight: 300; font-size: 20px; line-height: 27px;">The future plans for BuddyTalkie involve refining its design and advancing its capabilities. Key objectives include enhancing technology, miniaturizing the device, extending communication range, improving durability, including and expanding health monitoring features, expanding digital communication feature for integration to Autonomous Underwater Vehicles, integrating with dive planning systems, and fostering collaborations. These plans aim to establish BuddyTalkie as a leading communication device for safer and more efficient underwater exploration, meeting the evolving needs of divers and the underwater exploration community.</p>
			</header>
		</div>
	</section>

	<!-- AWARDS -->
	<section class="section" id="awards" style="background: #39B54A; padding-top: 60px; padding-bottom: 20px;">
		<div class="container">
			<header class="section-header">
				<h2 style="font-family: Helvetica, sans-serif; font-size: 48px; color: #FFF; font-weight: 700;">Awards</h2>
				<p style="color: #000; font-family: 'Helvetica Neue', sans-serif; font-weight: 300; font-size: 20px; line-height: 27px;">Our invention has achieved remarkable success in the renowned Ericsson Innovation Awards 2019. Out of hundreds worldwide applications, we secured an impressive 4th place. It was presented to esteemed jurors, including the Nobel Committee. We were also honored with the People's Choice Award.</p>
			</header>
		</div>
	</section>

	<!-- TEAM -->
	<section class="section" id="team" style="background: #FFF; padding-top: 60px; padding-bottom: 100px;">
		<div class="container">
			<header class="section-header">
				<h2 style="font-family: Helvetica, sans-serif; font-size: 48px; color: #39B54A; font-weight: 700; margin: 0; padding: 0; margin-bottom: 26px;">Team</h2>
				<p class="lead">Meet our small team that make these great project.</p>
			</header>

			<div class="row gap-y">
			
				<div class="col-6 col-sm-6 col-md-3 team-2" style="border-radius: 1000px !important">
					<a href="#">
						<img src="@/assets/img/team/kaan_alper.jpeg" alt="...">
					</a>
					<h5>Kaan Alper</h5>
					<div class="social social-brand">
						<a class="social-linkedin" href="https://linkedin.com/in/kaan-alper" target="_blank"><i class="fa fa-linkedin"></i></a>
					</div>
				</div>


				<div class="col-6 col-sm-6 col-md-3 team-2" style="border-radius: 1000px !important">
					<a href="#">
						<img src="@/assets/img/team/mutlu_dulger.jpeg" alt="...">
					</a>
					<h5>Mutlu Dülger</h5>
					<div class="social social-brand">
						<a class="social-linkedin" href="https://linkedin.com/in/mutludulger" target="_blank"><i class="fa fa-linkedin"></i></a>
					</div>
				</div>


				<div class="col-6 col-sm-6 col-md-3 team-2" style="border-radius: 1000px !important">
					<a href="#">
						<img src="@/assets/img/team/mert_gursoy.jpeg" alt="...">
					</a>
					<h5>Mert Gürsoy</h5>
					<div class="social social-brand">
						<a class="social-linkedin" href="https://linkedin.com/in/mert-gürsoy-a7042413b" target="_blank"><i class="fa fa-linkedin"></i></a>
					</div>
				</div>


				<div class="col-6 col-sm-6 col-md-3 team-2" style="border-radius: 1000px !important">
					<a href="#">
						<img src="@/assets/img/team/kagan_inan.jpeg" alt="...">
					</a>
					<h5>Kağan İnan</h5>
					<div class="social social-brand">
						<a class="social-linkedin" href="https://linkedin.com/in/kaganinan" target="_blank"><i class="fa fa-linkedin"></i></a>
					</div>
				</div>

			</div>
		</div>
	</section>

	<!-- FOOTER -->
	<footer class="footer" style="background-color: #0B0B0B; padding: 36px 0;">
		<div class="container">
			<div class="row gap-y align-items-center">

			<div class="col-md-3 text-center text-md-left">
				<a href="#"><img src="@/assets/img/logo-light.png" alt="logo" style="height: 42px"></a>
			</div>

			<div class="col-md-3"></div>

			<div class="col-md-6 text-center text-md-right" style="color: #FFF; font-family: 'Helvetica Neue', sans-serif; font-weight: 300; font-size: 16px;">
				Buddy Talkie © 2023. All rights reserved.
			</div>

			</div>
		</div>
	</footer>
</template>
